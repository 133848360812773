<template>
	<div class="row mt-5">
		<div class="col-12 mt-md-5 text-center">
			<img src="../assets/like.svg" alt="Club Global">
		</div>
		<div class="col-12 mt-3 text-center">
			<h4>Thanks for {{bookingItems.table ? 'reserving' : 'purchasing'}} the {{bookingItems.table ? 'table' : 'tickets'}} </h4>
		</div>
		<div class="col-12 mt-5 text-center">
			<h5 class="text-secondary">
				Download the app to access your {{bookingItems.table ? 'table' : 'tickets'}}
			</h5>
			<h6 class="mx-auto mt-3" style="max-width: 300px">You can view your {{bookingItems.table ? 'table' : 'ticket'}} receipt from your account on the Club Global app</h6>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";

	export default {
		name: "PaymentCompleted",

		beforeMount() {
			if (!this.bookingItems){
				this.$router.push('/');
			}
		},

		computed: {
			...mapGetters(['bookingItems']),
		}
	}
</script>